import { IS_NODE, IS_PROBUILDS } from "@/__main__/constants.mjs";
import router from "@/__main__/router.mjs";
import { HUB_SYMBOL_HOME } from "@/app/constants.mjs";
import {
  appRoutes as marketingAppRoutes,
  homepageRoutes,
} from "@/routes/marketing.mjs";
import globals from "@/util/global-whitelist.mjs";

export function shouldShowAds() {
  const { route } = router;
  if (!route) return false;
  const acceptableWidth = IS_NODE || globals.innerWidth >= 1366;

  if (IS_PROBUILDS) {
    return acceptableWidth;
  }

  return (
    acceptableWidth &&
    route.hubSymbol !== HUB_SYMBOL_HOME &&
    !route.currentPath.startsWith("/account") &&
    !route.currentPath.startsWith("/getting-started") &&
    !route.currentPath.endsWith("/map") &&
    !route.currentPath.includes("/patchnotes") &&
    !route.currentPath.includes("/season-review") &&
    !route.currentPath.startsWith("/tft/set-") &&
    !route.currentPath.startsWith("/lol/champions") &&
    !route.currentPath.startsWith("/palworld/database") &&
    !route.currentPath.startsWith("/tft/traits") &&
    !marketingAppRoutes.find((r) => r.component === route.component) &&
    !homepageRoutes.find((r) => r.component === route.component)
  );
}
