import { IS_PROBUILDS } from "@/__main__/constants.mjs";
import mainRefs from "@/__main__/refs.mjs";
import appRefs from "@/app/refs.mjs";
import AdWrapper, {
  SidebarAd,
  tileAdConfig,
} from "@/feature-ads-baseline/AdWrapper.jsx";
import HeaderWrapper, {
  externalContainerCss,
  titleBarCss,
} from "@/feature-ads-baseline/HeaderWrapper.jsx";
import matchTileAdRefs from "@/shared/match-tile-ad.refs.mjs";
import profileRefs from "@/shared/Profile.refs.jsx";
import mapOriginalRefs, {
  forceOverwriteIterable,
} from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  appShellRefs: appRefs.appShell,
  mainRefComponents: mainRefs.components,
  mainRefs,
  matchTileAdRefs,
  profileRefs,
});

export function setup() {
  const matchTiles = [tileAdConfig];
  matchTiles[forceOverwriteIterable] = true;
  original.set({
    mainRefComponents: {
      ContentWrapper: AdWrapper,
    },
    matchTileAdRefs: {
      tiles: matchTiles,
    },
    profileRefs: {
      SidebarAd,
    },
  });

  if (!IS_PROBUILDS) {
    original.set({
      mainRefComponents: {
        HeaderWrapper: HeaderWrapper,
      },
    });
  }

  original.append({
    appShellRefs: {
      titleBarClasses: [titleBarCss],
    },
    mainRefs: {
      externalContainerClasses: [externalContainerCss],
    },
  });
}

export function teardown() {
  original.restore();
}
