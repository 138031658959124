import React from "react";
import { css, styled } from "goober";
import { desktop } from "clutch/src/Style/style.mjs";

import { DISPLAY_AD_WIDTH } from "@/feature-ads-baseline/constants.mjs";
import { shouldShowAds } from "@/feature-ads-baseline/util.mjs";

export const externalContainerCss = () => css`
  --ad-rail-space: calc(${DISPLAY_AD_WIDTH}px + var(--sp-6));
`;

const Container = styled("div")`
  --header-container: calc(var(--sp-container) + var(--ad-rail-space));

  ${desktop} {
    --header-container: 100%;
  }
`;

export const titleBarCss = () => css`
  --header-container: calc(var(--sp-container) + var(--ad-rail-space));

  ${desktop} {
    --header-container: 100%;
  }
`;

function HeaderWrapper({ children }) {
  if (!shouldShowAds()) return children;
  return <Container>{children}</Container>;
}

export default HeaderWrapper;
