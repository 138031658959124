import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";

import { IS_APP } from "@/__main__/constants.mjs";
import { APP_SCROLLER, appURLs } from "@/app/constants.mjs";
import {
  DISPLAY_AD_CLASS,
  DISPLAY_AD_HEIGHT,
  DISPLAY_AD_WIDTH,
} from "@/feature-ads-baseline/constants.mjs";
import { shouldShowAds } from "@/feature-ads-baseline/util.mjs";
import CloseIcon from "@/inline-assets/close-icon.svg";
import ClientOnly from "@/util/ClientOnly.jsx";
import globals from "@/util/global-whitelist.mjs";
import { IS_APP_ROUTE } from "@/util/mod-pathname.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

const DisplayAdInfeedCSS = () => css`
  overflow: hidden;
  height: 90px;

  &:has(iframe) .placeholder {
    display: none !important;
  }

  .placeholder {
    position: relative;
    height: 100%;
  }
`;

const DisplayAdAnchorCSS = () => css`
  --width: 728px;
  height: 90px;
  width: var(--width);
  position: fixed;
  bottom: 0;
  pointer-events: none;
  /* prevent overlap with right rail ads */
  overflow: hidden;
  right: max(
    calc(${DISPLAY_AD_WIDTH}px + var(--sp-6)),
    calc(50vw - var(--width) / 2)
  );
  z-index: 14;
  &:has(iframe) {
    pointer-events: auto;
    background: var(--shade6);
  }
  .close-btn {
    padding: 0;
    --btn-size: var(--sp-4);
    background: var(--shade10-75);
    position: absolute;
    border-radius: 50%;
    top: calc(-1 * var(--btn-size) - var(--sp-1));
    left: calc(var(--width) - var(--btn-size));
    width: var(--btn-size);
    height: var(--btn-size);
    color: var(--shade0-75);
    cursor: pointer;
    border: 1px solid var(--shade0-50);
  }
  &:not(:has(iframe)) .close-btn {
    display: none;
  }
`;

const Container = styled("div")`
  position: relative;
  width: calc(var(--sp-container) + var(--ad-rail-space));
  max-width: 100%;

  .🤑-column {
    position: absolute;
    top: 0;
    left: calc(var(--sp-container) + var(--sp-4));
    height: 100%;
    & > * {
      position: sticky;
      top: var(--sp-4);
    }
  }

  .placeholder {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .route-wrapper {
    width: var(--sp-container) !important;
    max-width: 100%;
  }

  .🤑-container {
    --space: var(--sp-1);
    box-sizing: content-box;
    overflow: hidden;
    width: ${DISPLAY_AD_WIDTH}px;
    height: ${DISPLAY_AD_HEIGHT}px;
    background: var(--shade8);
    outline: 1px solid var(--shade7);
    border-radius: var(--br);
    padding: var(--space);
    margin-block-end: var(--sp-2);
    position: relative;

    & > * {
      position: absolute;
      top: var(--space);
      left: var(--space);
      width: calc(100% - var(--space) * 2);
      height: calc(100% - var(--space) * 2);
    }

    &:has(iframe) .placeholder,
    &:has(aditude-video-ad) .placeholder {
      display: none !important;
    }
  }

  .${DISPLAY_AD_CLASS}:not(:has(iframe)) {
    pointer-events: none;
  }
`;

function AdWrapper({ children }) {
  const innerRef = useRef(null);
  const showAds = shouldShowAds();
  useLayoutEffect(() => {
    if (!innerRef.current) return;
    const { current: adContainer } = innerRef;
    const doAlignment = () => {
      // whatever the fk happened... just find the align element
      const alignElement = findAlignElement();
      const offsetY = alignElement?.offsetTop || 0;
      adContainer.style.marginTop = `${offsetY}px`;
    };

    const mutObs = new MutationObserver(doAlignment);
    const resObs = new ResizeObserver(doAlignment);
    const scroller = globals.document.querySelector(`.${APP_SCROLLER}`);
    mutObs.observe(scroller, { childList: true, subtree: true });
    resObs.observe(scroller);
    doAlignment();

    return () => {
      mutObs.disconnect();
      resObs.disconnect();
    };
  }, [showAds]);

  const route = useRoute((a, b) => a?.currentPath === b?.currentPath, {
    isGlobal: true,
  });
  const shouldShowBanner = !/\/profile\//.test(route?.currentPath || "");

  if (!showAds) return children;

  return (
    <Container>
      {children}
      <div className="🤑-column">
        <div ref={innerRef}>
          <DisplayAd id={"display-rr-1"} />
          <DisplayAd id={"display-rr-2"} />
          {IS_APP_ROUTE ? <DisplayAd id={"display-rr-3"} /> : null}
        </div>
      </div>
      {shouldShowBanner && <AdhesionBannerContainer />}
    </Container>
  );
}

export function SidebarAd() {
  return (
    <DisplayAd id={"BLTZGG_DESKTOP_ROS_LR2"} style={{ marginBlockEnd: "0" }} />
  );
}

function DisplayAd({ id, placeholder = <AdPlaceholder />, className, style }) {
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const viewabilityObs = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const method = entry.isIntersecting ? "remove" : "add";
          const displayContainer = entry.target.querySelector(
            `.${DISPLAY_AD_CLASS}`,
          );
          if (!displayContainer) continue;
          displayContainer.classList[method]("unviewable");
        }
      },
      { threshold: 0.51 },
    );
    viewabilityObs.observe(containerRef.current);
    return () => {
      viewabilityObs.disconnect();
    };
  }, []);

  const showAds = shouldShowAds();
  if (!showAds) return null;

  return (
    <div
      ref={containerRef}
      style={style}
      className={className || "🤑-container"}
    >
      {placeholder ? (
        <ClientOnly>
          <div className="placeholder">{placeholder}</div>
        </ClientOnly>
      ) : null}
      <div id={id} className={DISPLAY_AD_CLASS} />
    </div>
  );
}

const maxIndex = 6;
const prefixes = ["tall", "slot"];
export const promoImgMap = {};
for (const prefix of prefixes) {
  promoImgMap[prefix] = new Array(maxIndex).fill(null).map((_, i) => {
    return `${appURLs.CDN}/self-promotion/premium/${prefix}_${i + 1}.webp`;
  });
}

const availableIndices = new Set(
  new Array(maxIndex).fill(null).map((_, i) => i + 1),
);
const unavailableIndices = new Set();

function AdPlaceholder({ prefix = "slot", img, link }) {
  const { t } = useTranslation();
  const [index, setIndex] = useState(null);

  useLayoutEffect(() => {
    if (img) return;
    let i;
    while (availableIndices.size && !availableIndices.has(i)) {
      i = ~~(Math.random() * (maxIndex - 1)) + 1;
    }
    setIndex(i);
    availableIndices.delete(i);
    unavailableIndices.add(i);
    return () => {
      availableIndices.add(i);
      unavailableIndices.delete(i);
    };
  }, [img]);

  if (!index && !img) return null;

  const imgUrl = img ? img : promoImgMap[prefix][index];
  const href = link
    ? link
    : `/pro?utm_source=blitz&utm_medium=ad&utm_campaign=blitz_ad_${index}`;
  const alt = !(img || link) ? t("common:getSubscriber", "Get Premium") : "Ad";

  return (
    <a href={href} target={IS_APP ? undefined : "_blank"} rel="noreferrer">
      <img src={imgUrl} alt={alt} />
    </a>
  );
}

function findAlignElement() {
  const header = globals.document.querySelector(".route-wrapper header");
  if (!header) return null;
  let current = header;
  let i = 0;
  while (i < 20) {
    current = header.nextSibling;
    if (current?.nodeType === Node.ELEMENT_NODE) break;
    i++;
  }
  return current;
}

function AdhesionBannerContainer() {
  const [visible, setVisible] = useState(true);

  const onUserClose = useCallback((e) => {
    e.preventDefault();
    setVisible(false);
  }, []);

  if (!visible) return null;

  return (
    <DisplayAd
      placeholder={null}
      id={"display-desktop-anchor"}
      style={{ maxHeight: "90px" }}
      className={DisplayAdAnchorCSS()}
    >
      <button className="close-btn" onClick={onUserClose}>
        <CloseIcon />
      </button>
    </DisplayAd>
  );
}

const ProfileTileAd = () => {
  const placeholderImage = `${appURLs.CDN}/self-promotion/tile-placeholder.webp`;

  return (
    <DisplayAd
      placeholder={<AdPlaceholder img={placeholderImage} />}
      id={"display-desktop-anchor-infeed"}
      className={DisplayAdInfeedCSS()}
    />
  );
};

export const tileAdConfig = {
  id: "in-feed-profile-ad",
  priority: 0,
  Component: ProfileTileAd,
};

export default AdWrapper;
